import * as React from "react"
import { StoreProvider } from "./src/context/store-context"
import fetch from 'isomorphic-fetch'
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'

import "./src/css/_style.css"
import "./src/css/_global.css"
// import "./src/styles/reset.css"
// import "./src/styles/variables.css"
// import "./src/styles/global.css"

// Create a function to create a new Apollo Client for each request
const createApolloClient = () => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: new createHttpLink({
      uri: `https://${process.env.GATSBY_SHOPIFY_STORE_NAME}.myshopify.com/api/${process.env.GATSBY_SHOPIFY_API_VERSION}/graphql.json`,
      headers: {
        'X-Shopify-Storefront-Access-Token': process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
        'Accept': 'application/json', // Change 'application/graphql' to 'application/json'
      },
      fetch,
    }),
  });
};

export const wrapRootElement = ({ element }) => (
  <StoreProvider>
    <ApolloProvider client={createApolloClient()}>{element}</ApolloProvider>
  </StoreProvider>
);